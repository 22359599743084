<script setup>
import { onUpdated, defineProps, ref } from 'vue';
import Highcharts from "highcharts";

// const props = defineProps(['domId'])

const props = defineProps({
  domId: {
    type: String,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
//   series: {
//     type: Array,
//     required: true,
//   },
});
const chart = ref(null)
onUpdated(() => {
    const highchartsOptions = {
        chart: {
            renderTo: props.domId
        },
        title: {
            text: '',
            align: 'left'
        },

        subtitle: {
            text: '',
            align: 'left'
        },

        yAxis: {
            title: {
                text: 'Order Status '
            }
        },

        xAxis: {
            categories:[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
            allowDecimals: false,
            accessibility: {
                rangeDescription: ''
            }
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                // pointStart: 2010
            }
        },

        series: props.data,

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }

    };
    chart.value = new Highcharts.chart(highchartsOptions);
})

</script>

<template>
    <div class="chart-div" :id="domId" ref="chartDiv"></div>
</template>