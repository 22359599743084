<script setup>
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
import ChartCard from "./component/ChartCard.vue";
import AreaChart from "./component/charts/AreaChart.vue";
import LineChart from "./component/charts/LineChart.vue";
import HeaderSection from "./component/HeaderSection.vue";
import StoreTable from "./component/StoreTable.vue";
import router from "../../../router";
import { ref, computed, watch } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import monthDayYear from "../../../services/dates/dateformatter";
import axios from "@/gateway/backendapi";
import {
  orderTrend,
  allOrder,
  orderStatusTrend,
} from "../../../services/ecommerce/ecommerceservice";

const { lgAndUp, xlAndUp } = deviceBreakpoint();
const loading = ref(false);
const allOrders = ref([]);
const orderTrendData = ref([]);
const orderStatusTrendData = ref([]);

const handleClick = () => {
  router.push("/tenant/store/add");
  console.log("button cliked");
};

const handleSelectionChange = (payload) => {
  console.log(payload, "he");
};
const date = (offDate) => {
  return monthDayYear.monthDayYear(offDate);
};
const serverOptions = ref({
  page: 1,
  rowsPerPage: 50,
});
watch(
  serverOptions.value,
  () => {
    getAllOrder();
  },
  { deep: true }
);

const orderheader = [
  { name: "Product name", value: "name" },
  { name: "Total Amount", value: "amount" },
  { name: "Status", value: "status" },
  { name: "Date", value: "date" },
  { name: "Action", value: "action" },
];

const getOrderTrend = async () => {
  loading.value = true;
  try {
    let response = await orderStatusTrend();
    orderStatusTrendData.value = response;
    loading.value = false;
  } catch (error) {
    loading.value = false;
    console.error(error);
  }
};
getOrderTrend();
const getOrderStatus = async () => {
  loading.value = true;
  try {
    let response = await orderTrend();
    orderTrendData.value = response;
    loading.value = false;
  } catch (error) {
    loading.value = false;
    console.error(error);
  }
};
getOrderStatus();
const getAllOrder = async () => {
  loading.value = true;
  try {
    let response = await allOrder(serverOptions.value.page);
    allOrders.value = response;
    loading.value = false;
  } catch (error) {
    loading.value = false;
    console.error(error);
  }
};
getAllOrder();
const orderSeries = computed(() => {
  return orderTrendData.value.map((i) => i.month);
});
const orderTrendChartData = computed(() => {
  if (orderTrendData.value.length === 0) return [];
  const ordersTrendItems = [];
  orderTrendData.value.forEach((item) => {
    ordersTrendItems.push(Math.abs(item.orders));
  });

  // Return the transformed data
  return [
    {
      name: "Orders",
      color: "#0745AF",
      data: ordersTrendItems,
    },
  ];
});
const orderStatusSeries = computed(() => {
  return orderTrendData.value.map((i) => i.month);
});
const orderStatusChartData = computed(() => {
  if (orderStatusTrendData.value.length === 0) return [];
  const ordersStatusItems = [];
  orderStatusTrendData.value.forEach((item) => {
    ordersStatusItems.push(Math.abs(item.orders));
  });

  // Return the transformed data
  return [
    {
      name: "Orders",
      color: "#0745AF",
      data: ordersStatusItems,
    },
  ];
});

const deleteOrder = (id) => {
  axios
    .delete(`/api/Ecommerce/DeleteOrder?orderId=${id}`)
    .then((res) => {
      console.log(res);
      ElMessage({
        type: "success",
        message: "Order deleted successfully",
        duration: 5000,
      });
      allOrders.value = allOrders.value.filter(
        (orderItem) => orderItem.id !== id
      );
    })
    .catch((err) => {
      if (err.response.status === 400) {
        ElMessage({
          type: "error",
          message: "Unable to delete",
          duration: 5000,
        });
      } else {
        ElMessage({
          type: "error",
          message: "Unable to delete",
          duration: 5000,
        });
      }
    });
};
const showConfirmModal = (id, index) => {
  ElMessageBox.confirm("Are you sure you want to proceed?", "Confirm delete", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "error",
  })
    .then(() => {
      deleteOrder(id, index);
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "Delete canceled",
        duration: 5000,
      });
    });
};
</script>

<template>
  <div class="container-top" :class="{ 'container-wide': lgAndUp || xlAndUp }">
    <HeaderSection
      title="Orders"
      @handleClick="handleClick"
      :breadcrumbs="{ name: 'Ecommerce > Orders', route: '/tenant/store' }"
    />

    <div class="mt-5" v-loading="loading">
      <StoreTable
        :data="allOrders"
        :headers="orderheader"
        :checkMultipleItem="false"
        @checkedrow="handleSelectionChange"
        headerText="All orders"
        emptyData="No Data yet"
        :viewall="{}"
      >
        <template v-slot:name="{ item }">
          <div @click="showMemberRow(item)" class="c-pointer">
            <div class="d-flex align-items-center gap-3" v-for="(itm, index) in item.orderItems" :key="index">
              <img :src="itm.product.imageURL" alt="" class="order-image" />
              <div class="ml-2">{{ itm.product.name }}</div>
            </div>
          </div>
        </template>
        <template v-slot:amount="{ item }">
          <div @click="showMemberRow(item)" class="c-pointer">
            {{ Math.abs(item.totalAmount).toLocaleString() }}
          </div>
        </template>
        <template v-slot:status="{ item }">
          <div @click="showMemberRow(item)" class="c-pointer">
            {{ item.orderStatus }}
          </div>
        </template>
        <template v-slot:date="{ item }">
          <div @click="showMemberRow(item)" class="c-pointer">
            {{ date(item.orderDate) }}
          </div>
        </template>
        <template v-slot:action="{ item }">
          <el-dropdown trigger="click">
            <el-icon>
              <MoreFilled />
            </el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <div
                    @click.prevent="showConfirmModal(item.id, index)"
                    class="text-color"
                  >
                    Delete
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </StoreTable>
    </div>
    <div class="mt-5">
      <div class="chart-bg">
        <div class="bg-white" v-loading="loading">
          <ChartCard title="Order Status Chart">
            <template #chart>
              <LineChart :data="orderStatusChartData" domId="chart2" />
              <!-- <LineChart domId="chart2" /> -->
            </template>
          </ChartCard>
        </div>
        <div class="bg-white mt-5" v-loading="loading">
          <ChartCard title="Order Trend">
            <template #chart>
              <AreaChart
                :series="orderSeries"
                :data="orderTrendChartData"
                domId="chart3"
              />
            </template>
          </ChartCard>
        </div>
      </div>
    </div>
  </div>
</template>

<style setup>
.chart-bg {
  border: 1px solid rgba(206, 206, 206, 1);
  background-color: rgba(245, 245, 245, 1);
  padding: 60px;
  border-radius: 8px;
}

.order-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 3px;
}

@media (max-width: 767px) {
  .chart-bg {
    padding: 10px;
  }
}
</style>
